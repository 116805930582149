import React from 'react'
import style from "./../style/about.module.css";
import Header from './sub/Header';
import Footer from './sub/Footer';
import about from "./../assets/abthome.png";
import license from "./../assets/license.png";
export default function About() {
  return (
    <div>
      <Header />
      <section className={style.hero}></section>
      <section className={style.about}>
        <figure>
          <h3>About Us</h3>
          <img src={about} alt="" />
        </figure>
        <div>
          <h3>About Us</h3>
          <p>
            Caregreek International Limited was established in 2018 and has over
            the years evolved to become the largest and most specialized
            cleaning company in Abuja. It is one of wholly citizen owned
            commercial cleaning company in Nigeria. The company registration
            number is RC1509998. It has extensive experience in cleaning all
            types of buildings such as schools, offices, industrial building
            (premises), hotels, shopping centre, government offices, commercial
            premises and hospitals, banks and others.
          </p>
          <p>
            The company’s success lies in its ability to train and manage
            hundreds of staff to achieve the stringent standards demanded by the
            customers we serve in Nigeria.
          </p>
          <p>
            Our services include the provision of Janitorial Services, hygiene
            supplies, pest control, once off‐ specialized cleaning, office
            plants supplies and landscaping and garden services and chemical
            supplies.
          </p>
        </div>
      </section>
      <section className={style.license}>
        <div>
          <h3>Licenses</h3>
          <p>
            Caregreek is certified by Environmental Health Officers Registration
            Council of Nigeria (EHORECON), Federal Ministry of Environment
            (FMEnv) and National Environmental Standards and Regulations
            Enforcement Agency (NESREA) which has determined that Caregreek
            meets the high standards set and expected by the Regulators and that
            the company is committed to making a good faith effort to resolve
            any consumer issues.
          </p>
        </div>
        <figure>
          <h3>Licenses</h3>
          <img src={license} alt="" />
        </figure>
      </section>
      <Footer />
    </div>
  );
}
