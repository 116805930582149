import React from "react";
import style from "./../style/service.module.css";
import Header from "./sub/Header";
import Footer from "./sub/Footer";
import service1 from "./../assets/service1.png";
import service2 from "./../assets/service2.png";
import service3 from "./../assets/service3.png";
import service4 from "./../assets/service4.png";
import service5 from "./../assets/service5.png";
import service6 from "./../assets/service6.png";
import service7 from "./../assets/service7.png";
import service8 from "./../assets/service8.png";
import service9 from "./../assets/service9.png";
import service10 from "./../assets/service10.png";
import service11 from "./../assets/service11.png";
import service12 from "./../assets/service12.png";
import service13 from "./../assets/service13.png";
import service14 from "./../assets/service14.png";
import service15 from "./../assets/service15.png";
import service16 from "./../assets/service16.png";
import service17 from "./../assets/service17.png";

export default function Service() {
  return (
    <div>
      <Header />
      <section className={style.hero}></section>
      <section className={style.services}>
        <h3>Our Services</h3>
        <div>
          <nav>
            <figure>
              {" "}
              <img src={service1} alt="" />
            </figure>
            <h4>Residential Cleaning Service</h4>
            <p>
              We take the time to visit your home and develop a unique cleaning plan to suit your demands and the unique features of your residence since we care about what's important to you. We never adopt an all-encompassing strategy. We pay close attention to every detail and make sure to address all of your doubts and demands before we move a muscle, so we are aware of what you want and you are aware of the services we offer.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service2} alt="" />
            </figure>
            <h4>Commercial Cleaning</h4>
            <p>
              You may not have the time to tidy up your office after a hard day at work. We can easily solve this dilemma. We make sure that your office and company building is always clean and tidy and we provide professional cleaning services utilizing our precise routine cleaning techniques to touch all surfaces, tiles, walls, carpets, IT equipment, hygiene facilities, and every corner of your office. We adhere to your demands and level of precision, making sure your office is clean, safe, and comfortable for you always.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service3} alt="" />
            </figure>
            <h4>Post Construction Cleaning</h4>
            <p>
              Constructing a new building or even renovating one isn’t complete without thorough cleaning. We provide detailed cleaning services in different phases. We provide an initial clean-up stage where debris and large items that cannot be vacuumed are removed, then we move on to the more detailed cleaning which involves vacuuming, cleaning walls, sinks, and toilets, encompassing every room and corner. After these phases, we have the final cleaning stage where we have a follow-up cleaning to make sure there are no dust smudges or imperfections.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service4} alt="" />
            </figure>
            <h4>Fumigation /Pest control</h4>
            <p>
              We utilize safe methods and strategies to eliminate pests as well as prevent unwanted pests. We offer fumigation and pest control services that get rid of all kinds of unwanted bugs as well as small mammals and reptiles like rodents, snakes, and much more. Most of these pests come with harmful diseases, which is why we use fumigation products that prevent the spread of diseases and eliminate contamination. We ensure that after our pest control services, you can enjoy a pest-free and disease-free building.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service5} alt="" />
            </figure>
            <h4>Carpet and Upholstering Cleaning</h4>
            <p>
              Carpets and sofas usually accumulate the most dust and debris in a building, which is unhygienic and also very detrimental to your health as well as triggers respiratory problems. We provide thorough carpet and upholstering cleaning; sofas, curtains, mattress, and much more. We utilize vacuum cleaners, and organic eco-friendly cleaning products to make sure your upholstery and carpets have a fresh-smelling scent and you have a comfortable and healthier environment to be in.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service6} alt="" />
            </figure>
            <h4>End of Tenancy Cleaning</h4>
            <p>
              Our cleaning services are professional and detailed according to your demands. We make sure to clean and tidy your property top to bottom and make sure your new tenant meets a sparkling and comfortable environment, ready to move into. Our services include dusting, vacuuming, upholstery, cleaning windows, toilets, bathrooms and so much more.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service7} alt="" />
            </figure>
            <h4>Site Clearing/ Grass Cutting</h4>
            <p>
              We utilize the safest cleaning and disposal methods to get rid of unwanted materials on your site. We also vacuum and make use of wet cleaning to eliminate dust accumulation. Our cleaning services are top-notch and highly detailed.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service8} alt="" />
            </figure>
            <h4>Landscaping/ Beautification</h4>
            <p>
              Our services improve your landscape design and make it more beautiful to the public eye. We provide special clean-up services that include trimming hedges, mowing, fertilization, hedge designs, as well as gardening services that involve planting beautiful flowers and numerous kinds of plants. Our services also come with regular maintenance and follow-up services to keep your landscape looking appealing and keep you satisfied.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service9} alt="" />
            </figure>
            <h4>Waste Management</h4>
            <p>
              We utilize safe and hygienic waste disposal services, such as garbage disposal, dumpster rentals, garbage collection and also making sure to recycle properly. Our services are clean and tidy, involving organization, collection, and full disposal or recycling of materials. We also save you valuable time and effort and ensure your waste is managed properly.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service10} alt="" />
            </figure>
            <h4>Facility Management</h4>
            <p>
              We offer professional facility management services that ensure a safe, sustainable and comfortable environment for you to function efficiently. We provide cleaning services in any kind of organization including offices, hotels, leisure areas, etc. We also include a professional support team for regular maintenance and a better functioning organization.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service11} alt="" />
            </figure>
            <h4>Gutter Cleaning</h4>
            <p>
              We use advanced hygiene technologies and gutter clearing vacuum machines to efficiently clear your gutters of debris and we also clear out blockages and make sure your gutters flow free and clean.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service12} alt="" />
            </figure>
            <h4>Swimming Pool Cleaning</h4>
            <p>
              Save yourself valuable time and effort while we work on cleaning your swimming pool spotless. We make sure to remove every kind of debris and we also wash down cartridges while utilizing safe chemicals to clean and maintain proper water PH balance. Our services also come with regular maintenance and support services.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service13} alt="" />
            </figure>
            <h4>Gardening</h4>
            <p>
              Our gardening services are top-notch, we utilize talented and professional landscapers and gardeners. Our services include mowing, trimming planting trees and flowers, full garden design and painting as well as support and regular maintenance.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service14} alt="" />
            </figure>
            <h4>De-cluttering</h4>
            <p>
              We are glad to provide decluttering services of any kind for you. In most homes, offices land, and any kind of building, clutter tends to build up and it inhibits work and makes you uncomfortable. We offer reliable services that result in a clutter-free and clean environment to ensure your utmost satisfaction.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service15} alt="" />
            </figure>
            <h4>Painting Services</h4>
            <p>
              Our services also include professional painting services for both interior and exterior residential painting, as well as commercial painting. We provide only top-quality eco-friendly paint products used by our talented professionals, who make your home or any kind of building appealing and beautiful.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service16} alt="" />
            </figure>
            <h4>Property Management</h4>
            <p>
              We provide complete property management services where the quality and consistency of our services are carried out by professionals. We take complete care of your property with strict adherence to your demands and requests, making sure that your property is the most comfortable to live in. Our services also come with a detailed follow-up and maintenance.
            </p>
          </nav>
          <nav>
            <figure>
              {" "}
              <img src={service17} alt="" />
            </figure>
            <h4>Tree Planting</h4>
            <p>
              We offer the very best tree planting services. Our professional gardeners provide services in line with your tree planting schemes and we work to provide the best results accordingly.
            </p>
          </nav>
        </div>
      </section>
      <Footer />
    </div>
  );
}
