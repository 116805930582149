import React, { useState } from 'react'
import style from "./../../style/sub/header.module.css";
import logo from './../../assets/logo.png'
import { NavLink, useNavigate } from 'react-router-dom';
import menu from "./../../assets/ham.png";
import cancel from "./../../assets/cancel.svg";
import { Link } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate()
    const [hamNav, setHamNav] = useState(false);
    function handleShow() {
      setHamNav(!hamNav);
    }
    const [sticky, setSticky] = useState(false);
    function handleScroll(e) {
      if (window.scrollY > 130) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
    window.addEventListener("scroll", handleScroll);

  return (
    <header className={sticky ? style.sticky : style.header}>
      <Link to='/'>
        <img src={logo} alt="" />
      </Link>
      <nav className={hamNav ? style.show : ""}>
        <NavLink to="/" onClick={handleShow}>
          Home
        </NavLink>
        <NavLink to="/about" onClick={handleShow}>
          About Us
        </NavLink>
        <NavLink to="/services" onClick={handleShow}>
          Services
        </NavLink>
        <NavLink to="/contact-us" onClick={handleShow}>
          Contact Us
        </NavLink>
        <button onClick={() => navigate('/contact-us')}>
          SCHEDULE SERVICE
        </button>
      </nav>
      <button onClick={() => navigate('/contact-us')}>SCHEDULE SERVICE</button>
      <img
        alt=""
        className={style.ham}
        src={hamNav ? cancel : menu}
        onClick={handleShow}
      />
    </header>
  );
}
