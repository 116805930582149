import React from 'react'
import style from "./../style/contact.module.css";
import Header from './sub/Header';
import Footer from './sub/Footer';
import location from "./../assets/location.png";
import call from "./../assets/call.png";
import mail from "./../assets/email.png";
import contact from "./../assets/contact1.png";

export default function Contact() {
  return (
    <div>
      <Header />
      <section className={style.hero}></section>
      <main className={style.main}>
        <section className={style.form}>
          <form action="https://formsubmit.co/info@caregreek.com" method="POST">
            <h3>Get In Touch</h3>
            <label>
              <span>Full Name</span>
              <input type="text"  name='Full Name'/>
            </label>
            <label>
              <span>Email</span>
              <input type="email" name='Email' />
            </label>
            <label>
              <span>Message</span>
              <input type="text" name='Message' />
            </label>
            <input type="hidden" name="_next" value="https://caregreek.com/"/>
            <input type="hidden" name="_subject" value="Caregreek SCHEDULE SERVICE!"/>
            <input type="hidden" name="_captcha" value="false"/>
            <input type="hidden" name="_template" value="table"/>
            <button>SUBMIT</button>
          </form>
          <div>
            <h3>Contact Information</h3>
            <span>
              <img src={location} alt="" /> Address: Caregreek Complex, Alexis Hotel, Jabi Abuja.
            </span>
            <span>
              <img src={mail} alt="" /> Email: info@caregreek.com
            </span>
            <span>
              <img src={call} alt="" />
              call:+234 902 066 4276  OR +234 807 769 3387
            </span>
          </div>
        </section>
        <figure>
          <img src={contact} alt="" />
        </figure>
      </main>
      <Footer />
    </div>
  );
}
