import React, { useState } from 'react';
import style from './../style/home.module.css';
import Footer from './sub/Footer';
import Header from './sub/Header';
import about from './../assets/abthome.png';
import check from './../assets/check.png';
import why from './../assets/why.png';
import service1 from './../assets/service1.png';
import service2 from './../assets/service2.png';
import service3 from './../assets/service3.png';
import service4 from './../assets/service4.png';
import service5 from './../assets/service5.png';
import service6 from './../assets/service6.png';
import team from './../assets/team.png';
import gallery1 from './../assets/gallery1.png';
import gallery2 from './../assets/gallery2.png';
import gallery3 from './../assets/gallery3.png';
import gallery4 from './../assets/gallery4.png';
import gallery5 from './../assets/gallery5.png';
import gallery6 from './../assets/gallery6.png';
import gallery7 from './../assets/gallery7.jpeg';
import gallery8 from './../assets/gallery8.jpeg';
import gallery9 from './../assets/gallery9.jpeg';
import gallery10 from './../assets/gallery10.jpeg';
import gallery11 from './../assets/gallery11.jpeg';
import gallery12 from './../assets/gallery12.jpeg';
import gallery13 from './../assets/gallery13.jpeg';
import gallery14 from './../assets/gallery14.jpeg';
import feedback from './../assets/feedback.png';
import star from './../assets/star.png';
import back from './../assets/back.png';
import next from './../assets/next.png';
import down from './../assets/down.png';
import left from './../assets/left.png';
import right from './../assets/right.png';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [gal, setGal] = useState(0);
  const [page, setPage] = useState(true);
  const [feed, setFeed] = useState(0);
  function handleBack() {
    if (feed >= 0) {
      return;
    }
    setFeed(feed + 100);
  }
  function handleNext() {
    if (feed <= -200) {
      return;
    }
    setFeed(feed - 100);
  }
  function handleOne() {
    setOne(!one);
    setTwo(false);
    setThree(false);
    setFour(false);
  }
  function handleTwo() {
    setTwo(!two);
    setOne(false);
    setThree(false);
    setFour(false);
  }
  function handleThree() {
    setThree(!three);
    setOne(false);
    setTwo(false);
    setFour(false);
  }
  function handleFour() {
    setFour(!four);
    setOne(false);
    setTwo(false);
    setThree(false);
  }
  function galNext() {
    if (window.innerWidth > 768) {
      setPage(false);
    } else {
      if (gal <= -1300) {
        return;
      }
      setGal(gal - 100);
    }
  }
  function galPrev() {
    if (window.innerWidth > 768) {
      setPage(true);
    } else {
      if (gal >= 0) {
        return;
      }
      setGal(gal + 100);
    }
  }
  let gal_style = {
    transform: 'translateX(' + gal + '% )',
  };
  return (
    <div>
      <Header />
      <section className={style.hero}>
        <h1>
          <b>Caregreek</b> We Are <br /> A Certified Cleaning <br />{' '}
          and Fumigation <br /> Company
        </h1>
        <button>SCHEDULE SERVICE</button>
      </section>
      <section className={style.about}>
        <figure>
          <h3>About Us</h3>
          <img src={about} alt="" />
        </figure>
        <div>
          <h3>About Us</h3>
          <p>
            Caregreek International Ltd. is your one-stop cleaning
            company that provides commercial cleaning services in
            Abuja. It is one of the biggest professional cleaning
            companies in Nigeria. The company was registered in 2018
            under the name "Caregreek International Ltd."
          </p>
          <p>
            It provides janitorial services, hygiene supplies, pest
            management, once-off specialized cleaning, office plant
            supplies, land cabin services, and chemical supplies.
          </p>
          <button onClick={() => navigate('/about')}>
            READ MORE
          </button>
        </div>
      </section>
      <section className={style.why}>
        <div>
          <h3>Why caregreek</h3>
          <ul>
            <li>
              <img src={check} alt="" />a superior clean through our
              trained staff and on-site supervision to ensure
              accountability each and every time;
            </li>
            <li>
              <img src={check} alt="" />a local operations team
              on-call 24 hours a day, available to work at a moment’s
              notice; and
            </li>
            <li>
              <img src={check} alt="" />a safe “green” clean with the
              use of environmentally friendly products, vacuums and
              microfiber dusting cloths.
            </li>
            <li>
              <img src={check} alt="" />
              all of our employees are trained professionals who are
              certified in their field.
            </li>
            <li>
              <img src={check} alt="" />
              we are fully licensed and insured. We guarantee 100%
              satisfaction and peace of mind.
            </li>
          </ul>
        </div>
        <figure>
          <h3>Why caregreek</h3>
          <img src={why} alt="" />
        </figure>
      </section>
      <section className={style.services}>
        <h3>Our Services</h3>
        <div>
          <nav>
            <figure>
              {' '}
              <img src={service1} alt="" />
            </figure>
            <h4>Residential Cleaning</h4>
            <p>
              We take the time to visit your home and develop a unique
              cleaning plan to suit your demands and the unique
              features of your residence since we care about what's
              important to you. We never adopt an all-encompassing
              strategy.
            </p>
          </nav>
          <nav>
            <figure>
              {' '}
              <img src={service2} alt="" />
            </figure>
            <h4>Commercial Cleaning</h4>
            <p>
              You may not have the time to tidy up your office after a
              hard day at work. We can easily solve this dilemma. We
              make sure that your office and company building is
              always clean and tidy.
            </p>
          </nav>
          <nav>
            <figure>
              {' '}
              <img src={service3} alt="" />
            </figure>
            <h4>Post Construction Cleaning</h4>
            <p>
              Constructing a new building or even renovating one isn’t
              complete without thorough cleaning. We provide detailed
              cleaning services in different phases.
            </p>
          </nav>
          <nav>
            <figure>
              {' '}
              <img src={service4} alt="" />
            </figure>
            <h4>Fumigation /Pest control</h4>
            <p>
              We utilize safe methods and strategies to eliminate
              pests as well as prevent unwanted pests. We offer
              fumigation and pest control services that get rid of all
              kinds of unwanted bugs as well as small mammals and
              reptiles like rodents, snakes, and much more.
            </p>
          </nav>
          <nav>
            <figure>
              {' '}
              <img src={service5} alt="" />
            </figure>
            <h4>Carpet and Upholstering Cleaning</h4>
            <p>
              Carpets and sofas usually accumulate the most dust and
              debris in a building, which is unhygienic and also very
              detrimental to your health as well as triggers
              respiratory problems.
            </p>
          </nav>
          <nav>
            <figure>
              {' '}
              <img src={service6} alt="" />
            </figure>
            <h4>End of Tenancy Cleaning</h4>
            <p>
              Our cleaning services are professional and detailed
              according to your demands. We make sure to clean and
              tidy your property top to bottom and make sure your new
              tenant meets a sparkling and comfortable environment,
              ready to move into.
            </p>
          </nav>
        </div>
        <button onClick={() => navigate('/services')}>
          VIEW MORE
        </button>
      </section>
      <section className={style.team}>
        <div>
          <h3>Our Team</h3>
          <p>
            Our employees are critical to the services we provide and
            we are very selective in taking on new staff. Our staffs
            has an excellent track record and many years of
            experiences and they are provided with regular training.
          </p>
        </div>
        <figure>
          <h3>Our Team</h3>
          <img src={team} alt="" />
        </figure>
      </section>
      <section className={style.gallery}>
        <h3>Gallery</h3>
        <section>
          <div>
            <img src={gallery1} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery2} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery3} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery4} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery5} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery6} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery7} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery8} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery9} alt="" style={gal_style} className={page ? style.showPage : style.hidePage} />
            <img src={gallery10} alt="" style={gal_style} className={page ? style.hidePage : style.showPage} />
            <img src={gallery11} alt="" style={gal_style} className={page ? style.hidePage : style.showPage} />
            <img src={gallery12} alt="" style={gal_style} className={page ? style.hidePage : style.showPage} />
            <img src={gallery13} alt="" style={gal_style} className={page ? style.hidePage : style.showPage} />
            <img src={gallery14} alt="" style={gal_style} className={page ? style.hidePage : style.showPage} />
          </div>
        </section>
        <nav>
          <button>
            <img src={left} alt="" onClick={galPrev} />
          </button>
          <button>
            <img src={right} alt="" onClick={galNext} />
          </button>
        </nav>
      </section>
      <section className={style.book}>
        <div>
          <h3>Let’s Help You With Your Cleaning</h3>
          <p>
            We aim at offering outstanding service and value in the
            cleaning industry. So, if you're looking to hire
            experienced cleaners to handle your residential as well as
            commercial apartment cleaning, you're in the right spot.
            Feel free to contact us.
          </p>
        </div>
        <form
          action="https://formsubmit.co/info@caregreek.com"
          method="POST"
        >
          <h3>Book Now</h3>
          <label>
            <span>Name</span>
            <input type="text" name="Name" />
          </label>
          <label>
            <span>Phone number</span>
            <input type="tel" name="Telephone" />
          </label>
          <label>
            <span>Email</span>
            <input type="email" name="Email" />
          </label>
          <label>
            <span>Location</span>
            <input type="text" name="Location" />
          </label>
          <input
            type="hidden"
            name="_next"
            value="https://caregreek.com/"
          />
          <input
            type="hidden"
            name="_subject"
            value="Book Service Ceregreek !"
          />
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_template" value="table" />
          <button type="submit">SCHEDULE SERVICE</button>
        </form>
        <h3>Let’s Help You With Your Cleaning</h3>
      </section>
      <section className={style.feedback}>
        <h3>What People Say About Us</h3>
        <div>
          <nav
            style={{
              transform: 'translateX(' + feed + '% )',
            }}
          >
            <img src={feedback} alt="" />
            <p>
              If you need to clean your house quickly, this service is
              fantastic. The team was great, they put in a lot of
              effort, and they did a fantastic job cleaning the entire
              flat. How they were able to get rid of the wall's
              previous stains truly impressed me. Many thanks!
            </p>
            <small>Sam Odion</small>
            <span>
              <img src={star} alt="" />
            </span>
          </nav>
          <nav
            style={{
              transform: 'translateX(' + feed + '% )',
            }}
          >
            <img src={feedback} alt="" />
            <p>
              For a very reasonable price, Caregreek offers dependable
              and effective service. No other business that offers
              office cleaning compares to their quality, despite my
              attempts. Highly Recommended!
            </p>
            <small>Sam Odion</small>
            <span>
              <img src={star} alt="" />
            </span>
          </nav>
          <nav
            style={{
              transform: 'translateX(' + feed + '% )',
            }}
          >
            <img src={feedback} alt="" />
            <p>
              I can only remark on how effective and meticulous the
              team is! Online service reservations were simple to
              make. The team was courteous and on time, and they
              completed the task before the deadline. The stated level
              of cleanliness was achieved everywhere. Additionally,
              the cost is reasonable.
            </p>
            <small>Sam Odion</small>
            <span>
              <img src={star} alt="" />
            </span>
          </nav>
        </div>
        <nav>
          <img src={back} alt="" onClick={handleBack} />
          <img src={next} alt="" onClick={handleNext} />
        </nav>
      </section>
      <section className={style.faq}>
        <h3>Frequently Asked Questions</h3>
        <div>
          <nav onClick={handleOne}>
            How much do your services cost?
            <img
              src={down}
              className={one ? style.trans : ''}
              alt=""
            />
          </nav>
          <p className={one ? '' : style.show}>
            We take great pride in being known as an economical,
            high-caliber janitorial service. Every household receives
            a cleaning schedule that is individually created by our
            experts to meet their demands. The size of your home and
            the specific items you want to be cleaned will determine
            the cost of our services.
          </p>
        </div>
        <div>
          <nav onClick={handleTwo}>
            What if the day of my cleaning appointment falls on a
            holiday?
            <img
              src={down}
              className={two ? style.trans : ''}
              alt=""
            />
          </nav>
          <p className={two ? '' : style.show}>
            In case one of your regular cleanings falls on a holiday,
            we'll get in touch with you. To help you get ready for the
            approaching holiday, our staff will also inform you of any
            extra housecleaning services we may offer you. We'll try
            our best to accommodate your schedule.
          </p>
        </div>
        <div>
          <nav onClick={handleThree}>
            Do I have to supply my cleaning tools or materials to your
            team?
            <img
              src={down}
              className={three ? style.trans : ''}
              alt=""
            />
          </nav>
          <p className={three ? '' : style.show}>
            No. All of the supplies we will need for cleaning are
            brought with us when we arrive, and we leave with them as
            well. To guarantee that we leave your home immaculate, we
            take great pride in using the best equipment and vacuums
            available. You are free to let us know if you have any
            recommendations for the cleaning agents we employ, and
            we'll do everything we can to accommodate your
            requirements.
          </p>
        </div>
        <div>
          <nav onClick={handleFour}>
            What happens if something is damaged during a service?
            <img
              src={down}
              className={four ? style.trans : ''}
              alt=""
            />
          </nav>
          <p className={four ? '' : style.show}>
            Every home we clean at Caregreek is given the highest
            respect. We'll try our best to fix or replace any broken
            items that arise during routine maintenance. Due to the
            comprehensive insurance coverage of our residential
            housekeepers, claims may be made as necessary.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}
